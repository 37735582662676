import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import Hero from './sections/hero/_index'
import PrivacyPolicy from './sections/politica-de-privacidade/_index'

import pageContext from './pageContext.json'

const PoliticaDePrivacidade = () => {
  const lang = 'pt'

  return (
    <Layout pageContext={pageContext}>
      <Hero lang={lang} />
      <PrivacyPolicy lang={lang} />
    </Layout>
  )
}

export default PoliticaDePrivacidade
